import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import Services from "../../Components/Services/Services";
import ContactFooter from "../../Components/ContactFooter/ContactFooter";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const ServiziPage = () => {
  return (
    <>
      <Seo locale="it" page="services" />
      <Header lang="it" />
      <HamburgerMenu />
      <Services lang="it" />
      <ContactFooter
        isDarkTheme={false}
        isBorderBottom={true}
        ctaInverted={false}
        additionalText="SERVIZI"
        lang="it"
      />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default ServiziPage;
